import React, { useContext, useEffect } from 'react';

import { Button, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';

import HomeFlashList from '@/components/home/home-flash-list';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';

import Placement1Component from '../ads-components/placements/placement1';
import Placement6Component from '../ads-components/placements/placement6';
import NoticiasListComponent from '../noticias/noticia-list-component';
import NewsIcon from '../shared/icons/news-icon';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';

import HomeActivities from './home-activities';
import HomeFixtures from './home-fixtures';

const HomePageComponent = ({ actividades, noticiasFlash, noticias }) => {
    const { state, dispatch } = useContext(Context);
    const router = useRouter();
    const { activities } = state;
    const isLargeDesktop = useMediaQuery('(min-width:1400px)');
    const isMedium = useMediaQuery('(min-width:576px)');

    useEffect(() => {
        if (actividades?.length > 0) {
            dispatch({
                type: REDUCERS.UPDATE_ACTIVITIES,
                payload: {
                    ...activities,
                    activities: [...activities.activities, ...actividades],
                },
            });
        }
    }, [actividades, noticiasFlash, noticias]);

    return (
        <div className="">
            <div className="row home-container__main-row mb-25">
                <div className="col-12">
                    <h1>Noticias, actividades y noticias flash</h1>
                </div>
                <div className="col-12 col-lg-8">
                    <NoticiasListComponent
                        numberOfArticles={1}
                        start={0}
                        noticias={noticias}
                        col={12}
                        postHeight={isLargeDesktop ? 488 : isMedium ? 440 : null}
                    />

                    <Placement1Component />

                    <NoticiasListComponent numberOfArticles={6} start={1} noticias={noticias} />

                    <Placement6Component />
                </div>
                <div className="col-12 col-lg-4" id="noticias-flash-container">
                    <LazyLoadWrapper>
                        <HomeActivities
                            customActivities={actividades}
                            isPage={false}
                            showHeader={true}
                            showFilters={false}
                            col={6}
                            customTake={15}
                        />
                    </LazyLoadWrapper>

                    <LazyLoadWrapper>
                        <HomeFlashList
                            take={5}
                            header="Noticias flash de fantasy fútbol"
                            hideHeader={false}
                            hideSearch={true}
                            noticiasFlash={noticiasFlash}
                        />
                    </LazyLoadWrapper>

                    <LazyLoadWrapper>
                        <HomeFixtures />
                    </LazyLoadWrapper>
                </div>

                <LazyLoadWrapper>
                    <NoticiasListComponent numberOfArticles={-1} start={7} noticias={noticias} col={4} />
                </LazyLoadWrapper>

                <LazyLoadWrapper>
                    <Button
                        color="primary"
                        aria-haspopup="true"
                        variant="outlined"
                        disableElevation
                        onClick={() => router.push(Rutas.NOTICIAS)} // Función para cargar más artículos
                        style={{ width: '100%' }}
                    >
                        <NewsIcon />
                        <span style={{ marginLeft: '4px' }}>Ver todas las noticias</span>
                    </Button>
                </LazyLoadWrapper>
            </div>
        </div>
    );
};

export default HomePageComponent;
